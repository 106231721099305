// WCC Projects > Pillar Awards
// src > ts > index.ts

// MODULES

import * as app from "./app";
import firebase from "firebase/app";

// TYPES

// CONSTANTS

// VARIABLES

let formId: string;
let form: Form;
let selectedFormEntryId: string;

// INITIAL SCRIPTS

$(() => {
  setDocumentListeners();
  getUser().then(user => {
    formId = user.uid;
    if (!formId) {
      document.location.href = "./signin.html";
    }
    const year = $("#year").val() as string;
    loadForm(year).then(() => {
      formDidLoad()
    }).catch(error => {
      console.error(error);
      if (error.code === "permission-denied") {
        console.error("Error: You do not have permission to view this form or it may not exist");
      }
      if (error === "Form does not exist") {
        createNewYearForm(user.displayName || undefined, user.email || "", user.uid).then(() => {
          window.location.reload();
        }).catch(error => {
          console.error(error);
          $("#permission-error-popup").show();
        });
      } else {
        $("#permission-error-popup").show();
      }
    });
  }).catch(error => {
    if (error === "No user signed in") {
      console.error(error);
      document.location.href = "./signin.html";
    }
  });
});

// FUNCTIONS

const setDocumentListeners = () => {
  $("#log-out").on("click", logOut);
  $(".pillar").on("click", didClickPillar);
  $(".dismiss-popup").on("click", hideAllPopups);
  $("#submit-form-entry").on("click", saveNewFormEntry);
  $(".popup").on("click", didClickOutPopup);
  $("#remove-pending-pillar").on("click", () => {
    showButtonLoading($("#remove-pending-pillar"));
    const formEntryType = formEntryKeyForPillarId(selectedFormEntryId);
    (form as any)[formEntryType] = null;
    saveFormChanges({ [formEntryType]: (form as any)[formEntryType] }).then(() => {
      window.location.reload();
    }).catch(error => {
      console.error(error);
    });
  });
  $("#submit-new").on("click", () => {
    showButtonLoading($("#submit-new"));
    const formEntryType = formEntryKeyForPillarId(selectedFormEntryId);
    (form as any)[formEntryType] = null;
    saveFormChanges({ [formEntryType]: (form as any)[formEntryType] }).then(() => {
      window.location.reload();
    }).catch(error => {
      console.error(error);
    });
  });
  $("#make-form-entry-popup #evidence").on("keyup", () => {
    const evidence = $("#make-form-entry-popup #evidence").val() as string;
    $("#character-count").text(evidence.length);
    $(".character-count-container").toggleClass("error", evidence.length > 120);
  });
};

const didClickOutPopup = (event: Event) => {
  if (event.target && $(event.target).closest(".popup-container").length === 0) {
    hideAllPopups();
  }
};

const hideAllPopups = () => {
  if (!$("body").hasClass("app-loading")) {
    $(".popup").hide();
  }
};

const loadForm = async (year: string) => {
  const formRef = app.database().collection("users").doc(formId).collection("years").doc(year);
  const doc = await formRef?.get();
  const databaseForm = doc.data() as any;
  if (!doc.exists) {
    throw "Form does not exist";
  }
  form = {
    ownerName: databaseForm.ownerName,
    ownerEmail: databaseForm.ownerEmail,
    faithFamily: databaseFormatFormEntryToFormEntry(databaseForm.faithFamily),
    faithMary: databaseFormatFormEntryToFormEntry(databaseForm.faithMary),
    faithSimplicity: databaseFormatFormEntryToFormEntry(databaseForm.faithSimplicity),
    faithWork: databaseFormatFormEntryToFormEntry(databaseForm.faithWork),
    faithPresence: databaseFormatFormEntryToFormEntry(databaseForm.faithPresence),
    learningFamily: databaseFormatFormEntryToFormEntry(databaseForm.learningFamily),
    learningMary: databaseFormatFormEntryToFormEntry(databaseForm.learningMary),
    learningSimplicity: databaseFormatFormEntryToFormEntry(databaseForm.learningSimplicity),
    learningWork: databaseFormatFormEntryToFormEntry(databaseForm.learningWork),
    learningPresence: databaseFormatFormEntryToFormEntry(databaseForm.learningPresence),
    wellbeingFamily: databaseFormatFormEntryToFormEntry(databaseForm.wellbeingFamily),
    wellbeingMary: databaseFormatFormEntryToFormEntry(databaseForm.wellbeingMary),
    wellbeingSimplicity: databaseFormatFormEntryToFormEntry(databaseForm.wellbeingSimplicity),
    wellbeingWork: databaseFormatFormEntryToFormEntry(databaseForm.wellbeingWork),
    wellbeingPresence: databaseFormatFormEntryToFormEntry(databaseForm.wellbeingPresence),
  };
};

const databaseFormatFormEntryToFormEntry = (databaseFormEntry: (FormEntry | null)): (FormEntry | null) => {
  if (databaseFormEntry) {
    if (databaseFormEntry.dateApproved) {
      databaseFormEntry.dateApproved = timestampToDate(databaseFormEntry.dateApproved);
    }
    databaseFormEntry.dateSubmitted = timestampToDate(databaseFormEntry.dateSubmitted);
    return databaseFormEntry;
  }
  return null;
};

const formDidLoad = () => {
  const email = app.authentication().currentUser?.email;
  if (!email) {
    document.location.href = "./signin.html";
    return;
  }
  $("#family-1 img").attr("src", `./images/family${form.faithFamily !== null ? (form.faithFamily.approved === null ? "-pending" : (form.faithFamily.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#family-1 .status").text(form.faithFamily !== null ? (form.faithFamily.approved === null ? "Pending" : (form.faithFamily.approved === true ? "Approved" : "Rejected")) : "");
  $("#family-1 .status").addClass(form.faithFamily !== null ? (form.faithFamily.approved === null ? "pending" : (form.faithFamily.approved === true ? "approved" : "rejected")) : "");
  $("#mary-1 img").attr("src", `./images/mary${form.faithMary !== null ? (form.faithMary.approved === null ? "-pending" : (form.faithMary.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#mary-1 .status").text(form.faithMary !== null ? (form.faithMary.approved === null ? "Pending" : (form.faithMary.approved === true ? "Approved" : "Rejected")) : "");
  $("#mary-1 .status").addClass(form.faithMary !== null ? (form.faithMary.approved === null ? "pending" : (form.faithMary.approved === true ? "approved" : "rejected")) : "");
  $("#simplicity-1 img").attr("src", `./images/simplicity${form.faithSimplicity !== null ? (form.faithSimplicity.approved === null ? "-pending" : (form.faithSimplicity.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#simplicity-1 .status").text(form.faithSimplicity !== null ? (form.faithSimplicity.approved === null ? "Pending" : (form.faithSimplicity.approved === true ? "Approved" : "Rejected")) : "");
  $("#simplicity-1 .status").addClass(form.faithSimplicity !== null ? (form.faithSimplicity.approved === null ? "pending" : (form.faithSimplicity.approved === true ? "approved" : "rejected")) : "");
  $("#work-1 img").attr("src", `./images/work${form.faithWork !== null ? (form.faithWork.approved === null ? "-pending" : (form.faithWork.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#work-1 .status").text(form.faithWork !== null ? (form.faithWork.approved === null ? "Pending" : (form.faithWork.approved === true ? "Approved" : "Rejected")) : "");
  $("#work-1 .status").addClass(form.faithWork !== null ? (form.faithWork.approved === null ? "pending" : (form.faithWork.approved === true ? "approved" : "rejected")) : "");
  $("#presence-1 img").attr("src", `./images/presence${form.faithPresence !== null ? (form.faithPresence.approved === null ? "-pending" : (form.faithPresence.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#presence-1 .status").text(form.faithPresence !== null ? (form.faithPresence.approved === null ? "Pending" : (form.faithPresence.approved === true ? "Approved" : "Rejected")) : "");
  $("#presence-1 .status").addClass(form.faithPresence !== null ? (form.faithPresence.approved === null ? "pending" : (form.faithPresence.approved === true ? "approved" : "rejected")) : "");
  $("#family-2 img").attr("src", `./images/family${form.learningFamily !== null ? (form.learningFamily.approved === null ? "-pending" : (form.learningFamily.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#family-2 .status").text(form.learningFamily !== null ? (form.learningFamily.approved === null ? "Pending" : (form.learningFamily.approved === true ? "Approved" : "Rejected")) : "");
  $("#family-2 .status").addClass(form.learningFamily !== null ? (form.learningFamily.approved === null ? "pending" : (form.learningFamily.approved === true ? "approved" : "rejected")) : "");
  $("#mary-2 img").attr("src", `./images/mary${form.learningMary !== null ? (form.learningMary.approved === null ? "-pending" : (form.learningMary.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#mary-2 .status").text(form.learningMary !== null ? (form.learningMary.approved === null ? "Pending" : (form.learningMary.approved === true ? "Approved" : "Rejected")) : "");
  $("#mary-2 .status").addClass(form.learningMary !== null ? (form.learningMary.approved === null ? "pending" : (form.learningMary.approved === true ? "approved" : "rejected")) : "");
  $("#simplicity-2 img").attr("src", `./images/simplicity${form.learningSimplicity !== null ? (form.learningSimplicity.approved === null ? "-pending" : (form.learningSimplicity.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#simplicity-2 .status").text(form.learningSimplicity !== null ? (form.learningSimplicity.approved === null ? "Pending" : (form.learningSimplicity.approved === true ? "Approved" : "Rejected")) : "");
  $("#simplicity-2 .status").addClass(form.learningSimplicity !== null ? (form.learningSimplicity.approved === null ? "pending" : (form.learningSimplicity.approved === true ? "approved" : "rejected")) : "");
  $("#work-2 img").attr("src", `./images/work${form.learningWork !== null ? (form.learningWork.approved === null ? "-pending" : (form.learningWork.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#work-2 .status").text(form.learningWork !== null ? (form.learningWork.approved === null ? "Pending" : (form.learningWork.approved === true ? "Approved" : "Rejected")) : "");
  $("#work-2 .status").addClass(form.learningWork !== null ? (form.learningWork.approved === null ? "pending" : (form.learningWork.approved === true ? "approved" : "rejected")) : "");
  $("#presence-2 img").attr("src", `./images/presence${form.learningPresence !== null ? (form.learningPresence.approved === null ? "-pending" : (form.learningPresence.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#presence-2 .status").text(form.learningPresence !== null ? (form.learningPresence.approved === null ? "Pending" : (form.learningPresence.approved === true ? "Approved" : "Rejected")) : "");
  $("#presence-2 .status").addClass(form.learningPresence !== null ? (form.learningPresence.approved === null ? "pending" : (form.learningPresence.approved === true ? "approved" : "rejected")) : "");
  $("#family-3 img").attr("src", `./images/family${form.wellbeingFamily !== null ? (form.wellbeingFamily.approved === null ? "-pending" : (form.wellbeingFamily.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#family-3 .status").text(form.wellbeingFamily !== null ? (form.wellbeingFamily.approved === null ? "Pending" : (form.wellbeingFamily.approved === true ? "Approved" : "Rejected")) : "");
  $("#family-3 .status").addClass(form.wellbeingFamily !== null ? (form.wellbeingFamily.approved === null ? "pending" : (form.wellbeingFamily.approved === true ? "approved" : "rejected")) : "");
  $("#mary-3 img").attr("src", `./images/mary${form.wellbeingMary !== null ? (form.wellbeingMary.approved === null ? "-pending" : (form.wellbeingMary.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#mary-3 .status").text(form.wellbeingMary !== null ? (form.wellbeingMary.approved === null ? "Pending" : (form.wellbeingMary.approved === true ? "Approved" : "Rejected")) : "");
  $("#mary-3 .status").addClass(form.wellbeingMary !== null ? (form.wellbeingMary.approved === null ? "pending" : (form.wellbeingMary.approved === true ? "approved" : "rejected")) : "");
  $("#simplicity-3 img").attr("src", `./images/simplicity${form.wellbeingSimplicity !== null ? (form.wellbeingSimplicity.approved === null ? "-pending" : (form.wellbeingSimplicity.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#simplicity-3 .status").text(form.wellbeingSimplicity !== null ? (form.wellbeingSimplicity.approved === null ? "Pending" : (form.wellbeingSimplicity.approved === true ? "Approved" : "Rejected")) : "");
  $("#simplicity-3 .status").addClass(form.wellbeingSimplicity !== null ? (form.wellbeingSimplicity.approved === null ? "pending" : (form.wellbeingSimplicity.approved === true ? "approved" : "rejected")) : "");
  $("#work-3 img").attr("src", `./images/work${form.wellbeingWork !== null ? (form.wellbeingWork.approved === null ? "-pending" : (form.wellbeingWork.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#work-3 .status").text(form.wellbeingWork !== null ? (form.wellbeingWork.approved === null ? "Pending" : (form.wellbeingWork.approved === true ? "Approved" : "Rejected")) : "");
  $("#work-3 .status").addClass(form.wellbeingWork !== null ? (form.wellbeingWork.approved === null ? "pending" : (form.wellbeingWork.approved === true ? "approved" : "rejected")) : "");
  $("#presence-3 img").attr("src", `./images/presence${form.wellbeingPresence !== null ? (form.wellbeingPresence.approved === null ? "-pending" : (form.wellbeingPresence.approved === true ? "-done" : "-rejected")) : ""}.png`);
  $("#presence-3 .status").text(form.wellbeingPresence !== null ? (form.wellbeingPresence.approved === null ? "Pending" : (form.wellbeingPresence.approved === true ? "Approved" : "Rejected")) : "");
  $("#presence-3 .status").addClass(form.wellbeingPresence !== null ? (form.wellbeingPresence.approved === null ? "pending" : (form.wellbeingPresence.approved === true ? "approved" : "rejected")) : "");
  $("#loading").hide();
  $(".student-view").show();
};

const timestampToDate = (timestamp: any): Date => {
  return (timestamp as unknown as firebase.firestore.Timestamp).toDate();
};

const shortDateString = (date: Date) => {
  const day = date.getDate().toString();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear().toString();
  let time = date.toLocaleTimeString([], { hour: "numeric", minute: "numeric" });
  return `${day} ${month} ${year} at ${time}`;
};

const didClickPillar = (event: JQuery.ClickEvent<HTMLElement>) => {
  selectedFormEntryId = event.currentTarget.id;
  const formEntry = formEntryForPillarId(selectedFormEntryId);
  if (formEntry) {
    if (formEntry.approved === false) {
      $("#rejected-form-entry-popup #section-name").text(formSectionForPillarId(selectedFormEntryId));
      $("#rejected-form-entry-popup #pillar-name").text(pillarNameForPillarId(selectedFormEntryId));
      $("#rejected-form-entry-popup #evidence").text(formEntry.evidence);
      $("#rejected-form-entry-popup #submitted-to").text(`${formEntry.teacherId}@parra.catholic.edu.au`);
      $("#rejected-form-entry-popup #date-submitted").text(shortDateString(formEntry.dateSubmitted));
      $("#rejected-form-entry-popup #comment").text(formEntry.comment ? formEntry.comment : "No comment");
      $("#rejected-form-entry-popup").show();
    } else if (formEntry.approved === true) {
      $("#done-form-entry-popup #section-name").text(formSectionForPillarId(selectedFormEntryId));
      $("#done-form-entry-popup #pillar-name").text(pillarNameForPillarId(selectedFormEntryId));
      $("#done-form-entry-popup #evidence").text(formEntry.evidence);
      $("#done-form-entry-popup #submitted-to").text(`${formEntry.teacherId}@parra.catholic.edu.au`);
      $("#done-form-entry-popup #date-submitted").text(shortDateString(formEntry.dateSubmitted));
      $("#done-form-entry-popup #date-approved").text(formEntry.dateApproved ? shortDateString(formEntry.dateApproved) : "No date");
      $("#done-form-entry-popup #comment").text(formEntry.comment ? formEntry.comment : "No comment");
      $("#done-form-entry-popup").show();
    } else {
      $("#pending-form-entry-popup #section-name").text(formSectionForPillarId(selectedFormEntryId));
      $("#pending-form-entry-popup #pillar-name").text(pillarNameForPillarId(selectedFormEntryId));
      $("#pending-form-entry-popup #evidence").text(formEntry.evidence);
      $("#pending-form-entry-popup #submitted-to").text(`${formEntry.teacherId}@parra.catholic.edu.au`);
      $("#pending-form-entry-popup #date-submitted").text(shortDateString(formEntry.dateSubmitted));
      $("#pending-form-entry-popup").show();
    }
  } else {
    $("#make-form-entry-popup #section-name").text(formSectionForPillarId(selectedFormEntryId));
    $("#make-form-entry-popup #pillar-name").text(pillarNameForPillarId(selectedFormEntryId));
    $("#make-form-entry-popup #submit-to").val("null");
    $("#make-form-entry-popup #evidence").val("");
    $("#character-count").text(0);
    // $("#make-form-entry-popup #teacher-name").text($("#make-form-entry-popup #submitted-to").val() as string);
    $("#make-form-entry-popup").show();
  }
};

const formEntryForPillarId = (id: string) => {
  if (id === "family-1") return form.faithFamily;
  if (id === "mary-1") return form.faithMary;
  if (id === "simplicity-1") return form.faithSimplicity;
  if (id === "work-1") return form.faithWork;
  if (id === "presence-1") return form.faithPresence;
  if (id === "family-2") return form.learningFamily;
  if (id === "mary-2") return form.learningMary;
  if (id === "simplicity-2") return form.learningSimplicity;
  if (id === "work-2") return form.learningWork;
  if (id === "presence-2") return form.learningPresence;
  if (id === "family-3") return form.wellbeingFamily;
  if (id === "mary-3") return form.wellbeingMary;
  if (id === "simplicity-3") return form.wellbeingSimplicity;
  if (id === "work-3") return form.wellbeingWork;
  if (id === "presence-3") return form.wellbeingPresence;
  return null;
};

const formEntryKeyForPillarId = (id: string) => {
  if (id === "family-1") return "faithFamily";
  if (id === "mary-1") return "faithMary";
  if (id === "simplicity-1") return "faithSimplicity";
  if (id === "work-1") return "faithWork";
  if (id === "presence-1") return "faithPresence";
  if (id === "family-2") return "learningFamily";
  if (id === "mary-2") return "learningMary";
  if (id === "simplicity-2") return "learningSimplicity";
  if (id === "work-2") return "learningWork";
  if (id === "presence-2") return "learningPresence";
  if (id === "family-3") return "wellbeingFamily";
  if (id === "mary-3") return "wellbeingMary";
  if (id === "simplicity-3") return "wellbeingSimplicity";
  if (id === "work-3") return "wellbeingWork";
  if (id === "presence-3") return "wellbeingPresence";
  return "";
};

const formSectionForPillarId = (id: string) => {
  const sectionNumber = id.slice(-1);
  if (sectionNumber === "1") return "Faith and Mission";
  if (sectionNumber === "2") return "Learning and Achievement";
  if (sectionNumber === "3") return "Wellbeing and Growth";
  return "";
};

const pillarNameForPillarId = (id: string) => {
  const pillarShort = id.slice(0, -2);
  if (pillarShort === "family") return "Family spirit";
  if (pillarShort === "mary") return "In the way of Mary";
  if (pillarShort === "simplicity") return "Simplicity";
  if (pillarShort === "work") return "Love of work";
  if (pillarShort === "presence") return "Presence";
  return "";
};

const saveNewFormEntry = () => {
  const $button = $("#submit-form-entry");
  if ($button.hasClass("disabled")) {
    return;
  }
  let evidence = ($("#make-form-entry-popup #evidence").val() as string).trim();
  const teacherId = $("#make-form-entry-popup #submit-to").val() as string;
  if (evidence.length > 120) {
    alert("Evidence can't be more than 120 characters");
    return;
  }
  if (evidence == "" || teacherId == null) {
    alert("Fill in all information for this pillar entry before submitting");
    return;
  }
  if (evidence == "") {
    evidence = "None";
  }
  showButtonLoading($button);
  const formEntryType = formEntryKeyForPillarId(selectedFormEntryId);
  (form as any)[formEntryType] = {
    evidence: evidence,
    teacherId: teacherId,
    approved: null,
    dateSubmitted: new Date(),
    dateApproved: null,
    comment: null,
  };
  saveFormChanges({ [formEntryType]: (form as any)[formEntryType] }).then(() => {
    window.location.reload();
  }).catch(error => {
    hideButtonLoading($button);
    console.error(error);
  });
};

const saveFormChanges = async (changes: any): Promise<void> => {
  const user = await getUser();
  if (user.email == null) {
    throw new Error("User does not have an associated email so cannot create forms");
  }
  changes.editDate = new Date();
  return app.database().collection("users").doc(formId).collection("years").doc("2025").set(changes, { merge: true });
};

const getUser = async (): Promise<firebase.User> => {
  return await new Promise((resolve, reject) => {
    app.authentication().onAuthStateChanged(user => {
      if (user == null) {
        reject("No user signed in");
      } else {
        resolve(user);
      }
    });
  });
};

const createNewYearForm = (displayName: (string | undefined), email: string, uid: string) => {
  return app.database().collection("users").doc(uid).collection("years").doc("2025").set({
    creationDate: app.database.FieldValue.serverTimestamp(),
    ownerName: displayName ?? email.split("@")[0],
    ownerEmail: email,
    faithFamily: null,
    faithMary: null,
    faithSimplicity: null,
    faithWork: null,
    faithPresence: null,
    learningFamily: null,
    learningMary: null,
    learningSimplicity: null,
    learningWork: null,
    learningPresence: null,
    wellbeingFamily: null,
    wellbeingMary: null,
    wellbeingSimplicity: null,
    wellbeingWork: null,
    wellbeingPresence: null,
  });
};

const logOut = () => {
  app.authentication().signOut();
  window.location.reload();
};

const showButtonLoading = ($button: JQuery<HTMLElement>) => {
  $button.find(".button-text").hide();
  $button.find(".button-loading").show();
  $("body").addClass("app-loading");
};

const hideButtonLoading = ($button: JQuery<HTMLElement>) => {
  $button.find(".button-text").show();
  $button.find(".button-loading").hide();
  $("body").removeClass("app-loading");
};
